
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Payment Transaction</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Payment Transaction</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="PaymentTransaction">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'PaymentTransactionList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8">

                  <div class="form-group ">
                    <label class="control-label">Transaction type</label>
                    <select class="form-control" style="width: 100%;" name="transactionType" v-model="value.transactionType" id="transactionType">
                      <option value="payment">payment</option>
                      <option value="refund">refund</option>
                    </select>

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Transaction-Id</label>
                    <input type="text" id="transactionId" name="transactionId" v-model="value.transactionId" class="form-control transactionId" placeholder="Transaction-Id">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Merchant Transaction-Id</label>
                    <input type="text" id="merchantTransactionId" name="merchantTransactionId" v-model="value.merchantTransactionId" class="form-control merchantTransactionId" placeholder="Merchant Transaction-Id">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Gateway Transaction-Id</label>
                    <input type="text" id="gatewayTransactionId" name="gatewayTransactionId" v-model="value.gatewayTransactionId" class="form-control gatewayTransactionId" placeholder="Gateway Transaction-Id">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Account-Id</label>
                    <input type="text" id="accountId" name="accountId" v-model="value.accountId" class="form-control accountId" placeholder="Account-Id">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Amount</label>
                    <input type="text" id="amount" name="amount" v-model="value.amount" class="form-control amount" placeholder="Amount">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Currency</label>
                    <input type="text" id="currency" name="currency" v-model="value.currency" class="form-control currency" placeholder="Currency">

                  </div>
                  <div class="form-group required">
                    <label class="control-label">Processed Amount</label>
                    <input type="text" id="processedAmount" name="processedAmount" v-bind:class="{'has-error' : errors.has('processedAmount')}" v-validate="'required'" v-model="value.processedAmount" class="form-control processedAmount" placeholder="Processed Amount">
                    <div class="help text-danger" v-show="errors.has('processedAmount')">
                      {{ errors.first("processedAmount") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Processed currency</label>
                    <input type="text" id="processedCurrency" name="processedCurrency" v-model="value.processedCurrency" class="form-control processedCurrency" placeholder="Processed currency">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Payment Status</label>
                    <select class="form-control" style="width: 100%;" name="paymentStatus" v-model="value.paymentStatus" id="paymentStatus">
                      <option value="INITIATED">INITIATED</option>
                      <option value="PENDING">PENDING</option>
                      <option value="SUCCESS">SUCCESS</option>
                      <option value="FAILED">FAILED</option>
                      <option value="UNKNOWN">UNKNOWN</option>
                      <option value="CANCELLED">CANCELLED</option>
                    </select>

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Gateway Error-code</label>
                    <input type="text" id="gatewayErrorCode" name="gatewayErrorCode" v-model="value.gatewayErrorCode" class="form-control gatewayErrorCode" placeholder="Gateway Error-code">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Gateway Error message</label>
                    <input type="text" id="gatewayErrorMsg" name="gatewayErrorMsg" v-model="value.gatewayErrorMsg" class="form-control gatewayErrorMsg" placeholder="Gateway Error message">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Remarks</label>
                    <input type="text" id="remarks" name="remarks" v-model="value.remarks" class="form-control remarks" placeholder="Remarks">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Channel</label>
                    <input type="text" id="channel" name="channel" v-model="value.channel" class="form-control channel" placeholder="Channel">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Merchant Callback-URL</label>
                    <input type="text" id="merchantCallbackUrl" name="merchantCallbackUrl" v-model="value.merchantCallbackUrl" class="form-control merchantCallbackUrl" placeholder="Merchant Callback-URL">

                  </div>
                  <div class="form-group required">
                    <label class="control-label">Gateway-Id</label>
                    <input type="text" id="gatewayId" name="gatewayId" v-bind:class="{'has-error' : errors.has('gatewayId')}" v-validate="'required'" v-model="value.gatewayId" class="form-control gatewayId" placeholder="Gateway-Id">
                    <div class="help text-danger" v-show="errors.has('gatewayId')">
                      {{ errors.first("gatewayId") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Merchant-Id</label>
                    <input type="text" id="merchantId" name="merchantId" v-model="value.merchantId" class="form-control merchantId" placeholder="Merchant-Id">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Gateway Parameters</label>
                    <input type="text" id="gatewayParams" name="gatewayParams" v-model="value.gatewayParams" class="form-control gatewayParams" placeholder="Gateway Parameters">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Additional Properties</label>
                    <input type="text" id="additionalProperties" name="additionalProperties" v-model="value.additionalProperties" class="form-control additionalProperties" placeholder="Additional Properties">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Order-Id</label>
                    <input type="text" id="orderBookingId" name="orderBookingId" v-model="value.orderBookingId" class="form-control orderBookingId" placeholder="Order-Id">

                  </div>
                  <div class="form-group ">
                    <label class="control-label">Reference Payment-Id</label>
                    <input type="text" id="referencePaymentId" name="referencePaymentId" v-model="value.referencePaymentId" class="form-control referencePaymentId" placeholder="Reference Payment-Id">

                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import MasterLayout from "@/components/layouts/MasterLayoutComponent";
import { DepartmentsAPI, CommonAPI } from "@/services/api";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";

export default {
  name: "PaymentTransactionForm",
  components: {
    DatePicker
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},

    value: {


    },
    pre: {

    }
  }),
  methods: {



    handleSubmission () {
      let _this = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          _this.is_loading = true;
          let api_url = "/payment_transactions";
          let method = "POST";
          if (_this.is_edit) {
            api_url = "/payment_transactions/" + _this.$route.params.id
            method = "PUT";
          }
          axios({
            method: method, url: api_url,
            data: _this.value,
            headers: { "Content-Type": "application/json" },
          }).then(function (response) {
            _this.$toaster.success(response.data.message)
            _this.$router.push({ "name": "PaymentTransactionList" });
          }).catch((errors) => {
            _this.$setErrorsFromResponse(errors.response.data);
          }).finally(() => {
            _this.is_loading = false;
          });
        }
      });
    },
    getDetail: function (id) {
      return new Promise(resolve => {
        let api_url = "/payment_transactions/" + id;
        let _this = this;
        axios.get(api_url)
          .then(response => {
            _this.value = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.log("error " + error);
            resolve(error);
          });
      });
    },
    async initialize () {
      this.is_loading = true;
      this.params = this.$route.params;

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id);
      }
      this.is_loading = false;
    }
  },
  mounted () {
    this.initialize()
  },
  created () {
    this.$emit("update:layout", MasterLayout);
  },
}
</script>

